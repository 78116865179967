export const ALL_ADPACKAGES_REQUEST = "ALL_ADPACKAGES_REQUEST";
export const ALL_ADPACKAGES_SUCCESS = "ALL_ADPACKAGES_SUCCESS";
export const ALL_ADPACKAGES_FAIL = "ALL_ADPACKAGES_FAIL";
export const ALL_ADPACKAGES_RESET = "ALL_ADPACKAGES_RESET";

export const NEW_ADPACKAGE_REQUEST = "NEW_ADPACKAGE_REQUEST";
export const NEW_ADPACKAGE_SUCCESS = "NEW_ADPACKAGE_SUCCESS";
export const NEW_ADPACKAGE_RESET = "NEW_ADPACKAGE_RESET";
export const NEW_ADPACKAGE_FAIL = "NEW_ADPACKAGE_FAIL";

export const GET_ADPACKAGE_REQUEST = "GET_ADPACKAGE_REQUEST";
export const GET_ADPACKAGE_SUCCESS = "GET_ADPACKAGE_SUCCESS";
export const GET_ADPACKAGE_RESET = "GET_ADPACKAGE_RESET";
export const GET_ADPACKAGE_FAIL = "GET_ADPACKAGE_FAIL";

export const UPDATE_ADPACKAGE_REQUEST = "UPDATE_ADPACKAGE_REQUEST";
export const UPDATE_ADPACKAGE_SUCCESS = "UPDATE_ADPACKAGE_SUCCESS";
export const UPDATE_ADPACKAGE_RESET = "UPDATE_ADPACKAGE_RESET";
export const UPDATE_ADPACKAGE_FAIL = "UPDATE_ADPACKAGE_FAIL";

export const RESET_ADPACKAGE_REQUEST = "RESET_ADPACKAGE_REQUEST";
export const DELETE_ADPACKAGE_REQUEST = "DELETE_ADPACKAGE_REQUEST";
export const DELETE_ADPACKAGE_SUCCESS = "DELETE_ADPACKAGE_SUCCESS";
export const DELETE_ADPACKAGE_FAIL = "DELETE_ADPACKAGE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
