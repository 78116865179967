import React, { useState } from "react";
import { useEffect } from "react";
import LanguagesList from "./languages.json";
import SelectInput from './Select';


const Languages = ({onChange:handleChange, lang="en"}) => {
    
    const [language, setLanguage] = useState({
        label: LanguagesList.default.name,
        value: LanguagesList.default.code,
    });

    useEffect(()=>{
        let temp = LanguagesList.all.find((e)=>e.code==lang);
        setLanguage({
          label: temp.name,
          value: temp.code,
        });
    },[]);

    return (
      <SelectInput
        label="Language"
        name="language"
        specifier="code"
        text="name"
        value={language}
        handleChange={(e) => {
          setLanguage({ label: e.label, value: e.value });
          handleChange({ name: "lang", value: e.value });
        }}
        options={LanguagesList.all}
      />
    );
}

export default Languages;