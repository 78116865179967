export const ALL_CATEGORY_REQUEST = "ALL_CATEGORY_REQUEST";
export const ALL_CATEGORY_SUCCESS = "ALL_CATEGORY_SUCCESS";
export const ALL_CATEGORY_FAIL = "ALL_CATEGORY_FAIL";
export const ALL_CATEGORY_RESET = "ALL_CATEGORY_RESET";

export const NEW_CATEGORY_REQUEST = "NEW_CATEGORY_REQUEST";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const NEW_CATEGORY_FAIL = "NEW_CATEGORY_FAIL";
export const NEW_CATEGORY_RESET = "NEW_CATEGORY_RESET";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
export const GET_CATEGORY_RESET = "GET_CATEGORY_RESET";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const ALL_SUB_CATEGORY_REQUEST = "ALL_SUB_CATEGORY_REQUEST";
export const ALL_SUB_CATEGORY_SUCCESS = "ALL_SUB_CATEGORY_SUCCESS";
export const ALL_SUB_CATEGORY_FAIL = "ALL_SUB_CATEGORY_FAIL";

export const GET_SUB_CATEGORY_REQUEST = "GET_SUB_CATEGORY_REQUEST";
export const GET_SUB_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_SUCCESS";
export const GET_SUB_CATEGORY_FAIL = "GET_SUB_CATEGORY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
