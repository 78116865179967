export const ALL_ACTIVITY_REQUEST = "ALL_ACTIVITY_REQUEST";
export const ALL_ACTIVITY_SUCCESS = "ALL_ACTIVITY_SUCCESS";
export const ALL_ACTIVITY_FAIL = "ALL_ACTIVITY_FAIL";
export const ALL_ACTIVITY_RESET = "ALL_ACTIVITY_RESET";

export const NEW_ACTIVITY_REQUEST = "NEW_ACTIVITY_REQUEST";
export const NEW_ACTIVITY_SUCCESS = "NEW_ACTIVITY_SUCCESS";
export const NEW_ACTIVITY_FAIL = "NEW_ACTIVITY_FAIL";
export const NEW_ACTIVITY_RESET = "NEW_ACTIVITY_RESET";

export const GET_ACTIVITY_REQUEST = "GET_ACTIVITY_REQUEST";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";
export const GET_ACTIVITY_RESET = "GET_ACTIVITY_RESET";

export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL";

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
