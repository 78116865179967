export const DateFormat = (parm, time = false) => {

  if (parm) {
    let date = new Date(parm);
    return date.toDateString();
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let month = date.getMonth()+1;

    if (month < 10) {
      month = "0" + (month);
    }

    let response = `${day}-${month}-${date.getFullYear()}`;
    if (time)
      response += ` ${date.getUTCHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    return response;
  } else return "None";
};
