export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_STATES_REQUEST = "GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";
export const GET_STATES_RESET = "GET_STATES_RESET";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";
export const GET_CITIES_RESET = "GET_CITIES_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
