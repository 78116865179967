export const ADMIN_QUERIES_REQUEST = "ADMIN_QUERIES_REQUEST";
export const ADMIN_QUERIES_SUCCESS = "ADMIN_QUERIES_SUCCESS";
export const ADMIN_QUERIES_FAIL = "ADMIN_QUERIES_FAIL";

export const ALL_QUERIES_REQUEST = "ALL_QUERIES_REQUEST";
export const ALL_QUERIES_SUCCESS = "ALL_QUERIES_SUCCESS";
export const ALL_QUERIES_FAIL = "ALL_QUERIES_FAIL";
export const ALL_QUERIES_RESET = "ALL_QUERIES_RESET";

export const NEW_QUERY_REQUEST = "NEW_QUERY_REQUEST";
export const NEW_QUERY_SUCCESS = "NEW_QUERY_SUCCESS";
export const NEW_QUERY_RESET = "NEW_QUERY_RESET";
export const NEW_QUERY_FAIL = "NEW_QUERY_FAIL";

export const GET_QUERY_REQUEST = "GET_QUERY_REQUEST";
export const GET_QUERY_SUCCESS = "GET_QUERY_SUCCESS";
export const GET_QUERY_RESET = "GET_QUERY_RESET";
export const GET_QUERY_FAIL = "GET_QUERY_FAIL";

export const UPDATE_QUERY_REQUEST = "UPDATE_QUERY_REQUEST";
export const UPDATE_QUERY_SUCCESS = "UPDATE_QUERY_SUCCESS";
export const UPDATE_QUERY_RESET = "UPDATE_QUERY_RESET";
export const UPDATE_QUERY_FAIL = "UPDATE_QUERY_FAIL";

export const RESET_QUERY_REQUEST = "RESET_QUERY_REQUEST";
export const DELETE_QUERY_REQUEST = "DELETE_QUERY_REQUEST";
export const DELETE_QUERY_SUCCESS = "DELETE_QUERY_SUCCESS";
export const DELETE_QUERY_FAIL = "DELETE_QUERY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
