export const ADMIN_PACKAGES_REQUEST = "ADMIN_PACKAGES_REQUEST";
export const ADMIN_PACKAGES_SUCCESS = "ADMIN_PACKAGES_SUCCESS";
export const ADMIN_PACKAGES_FAIL = "ADMIN_PACKAGES_FAIL";

export const ALL_PACKAGES_REQUEST = "ALL_PACKAGES_REQUEST";
export const ALL_PACKAGES_SUCCESS = "ALL_PACKAGES_SUCCESS";
export const ALL_PACKAGES_FAIL = "ALL_PACKAGES_FAIL";
export const ALL_PACKAGES_RESET = "ALL_PACKAGES_RESET";

export const NEW_PACKAGE_REQUEST = "NEW_PACKAGE_REQUEST";
export const NEW_PACKAGE_SUCCESS = "NEW_PACKAGE_SUCCESS";
export const NEW_PACKAGE_RESET = "NEW_PACKAGE_RESET";
export const NEW_PACKAGE_FAIL = "NEW_PACKAGE_FAIL";

export const GET_PACKAGE_REQUEST = "GET_PACKAGE_REQUEST";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_RESET = "GET_PACKAGE_RESET";
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL";

export const UPDATE_PACKAGE_REQUEST = "UPDATE_PACKAGE_REQUEST";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_RESET = "UPDATE_PACKAGE_RESET";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

export const RESET_PACKAGE_REQUEST = "RESET_PACKAGE_REQUEST";
export const DELETE_PACKAGE_REQUEST = "DELETE_PACKAGE_REQUEST";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
