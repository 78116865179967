export const ADMIN_LISTS_REQUEST = "ADMIN_LISTS_REQUEST";
export const ADMIN_LISTS_SUCCESS = "ADMIN_LISTS_SUCCESS";
export const ADMIN_LISTS_FAIL = "ADMIN_LISTS_FAIL";

export const ALL_LISTS_REQUEST = "ALL_LISTS_REQUEST";
export const ALL_LISTS_SUCCESS = "ALL_LISTS_SUCCESS";
export const ALL_LISTS_FAIL = "ALL_LISTS_FAIL";

export const UPDATE_LIST_REQUEST = "UPDATE_LIST_REQUEST";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";
export const UPDATE_LIST_RESET = "UPDATE_LIST_RESET";
export const UPDATE_LIST_FAIL = "UPDATE_LIST_FAIL";

export const LIST_STATUS_REQUEST = "LIST_STATUS_REQUEST";
export const LIST_STATUS_SUCCESS = "LIST_STATUS_SUCCESS";
export const LIST_STATUS_FAIL = "LIST_STATUS_FAIL";

export const LIST_DELETE_REQUEST = "LIST_DELETE_REQUEST";
export const LIST_DELETE_SUCCESS = "LIST_DELETE_SUCCESS";
export const LIST_DELETE_FAIL = "LIST_DELETE_FAIL";

export const LIST_DETAILS_REQUEST = "LIST_DETAILS_REQUEST";
export const LIST_DETAILS_SUCCESS = "LIST_DETAILS_SUCCESS";
export const LIST_DETAILS_FAIL = "LIST_DETAILS_FAIL";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
