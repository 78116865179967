import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../layout/common/Input";
import { create, fetch, update } from "../../../actions/activityActions";
import { NEW_ACTIVITY_RESET } from "../../../constants/businessActivityConstants";
import Languages from "../../layout/common/Language";

const EditActivity = () => {
  const pageName = "Edit Activity";
  const alert = useAlert();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [lang, setLang] = useState("en");
  const [formData, setFormData] = useState({});

  const handleChange = ({ name, value }) => {
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const { loading,isUpdated, isCreated, activity, error } = useSelector((state) => {
    return state.activity;
  });

  useEffect(() => {
    dispatch(fetch({ id: params.id }));
  }, []);

  useEffect(() => {
    if (activity) {
      setFormData({ ...activity });
    }
  }, [activity]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      return;
    }

    if (isCreated) {
      alert.success(`${pageName} added successfully`);
      dispatch({ type: NEW_ACTIVITY_RESET });
      navigate("/activities");
    }

    if (isUpdated) {
      alert.success(`${pageName} updated successfully`);
      dispatch({ type: NEW_ACTIVITY_RESET });
      navigate("/activities");
    }
    
    // alert.success("Updated Successfully");
  }, [error, activity]);

  const setDate = (name) => (value) => handleChange({ name, value });

  const submitHandler = (e) => {
    e.preventDefault();
    if (params.id) {
      dispatch(update({id:params.id, formData, lang}));
    } else {
      dispatch(create(formData));
    }
  };

  const onLanguageChange = async (obj) => {
    setLang(obj.value);
    dispatch(fetch({ id: params.id, lang:obj.value }));
  };

  return (
    <Fragment>
      <MetaData title={params.id ? `Update ${pageName}` : `New ${pageName}`} />
      <div className="">
        {/* <h1>Update List</h1> */}
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="card">
              <div className="card-header">
                <h4>{params.id ? `Update ${pageName}` : `New ${pageName}`}</h4>
              </div>
              <div className="card-body">
                <form
                  style={{ padding: "20px" }}
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-12">
                      <Input
                        label="Name"
                        name="name"
                        value={formData.name || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div className="col-12">
                      <Languages lang={lang} onChange={onLanguageChange} />
                    </div>
                  </div>

                  <button
                    style={{ width: "300px", margin: "auto" }}
                    id="login_button"
                    type="submit"
                    className="btn btn-primary btn-block py-3"
                    disabled={loading ? true : false}
                  >
                    {params.id ? "Update" : "Save"}
                  </button>
                </form>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default EditActivity;
