export const ALL_ADCLASS_REQUEST = "ALL_ADCLASS_REQUEST";
export const ALL_ADCLASS_SUCCESS = "ALL_ADCLASS_SUCCESS";
export const ALL_ADCLASS_FAIL = "ALL_ADCLASS_FAIL";
export const ALL_ADCLASS_RESET = "ALL_ADCLASS_RESET";

export const NEW_ADCLASS_REQUEST = "NEW_ADCLASS_REQUEST";
export const NEW_ADCLASS_SUCCESS = "NEW_ADCLASS_SUCCESS";
export const NEW_ADCLASS_RESET = "NEW_ADCLASS_RESET";
export const NEW_ADCLASS_FAIL = "NEW_ADCLASS_FAIL";

export const GET_ADCLASS_REQUEST = "GET_ADCLASS_REQUEST";
export const GET_ADCLASS_SUCCESS = "GET_ADCLASS_SUCCESS";
export const GET_ADCLASS_RESET = "GET_ADCLASS_RESET";
export const GET_ADCLASS_FAIL = "GET_ADCLASS_FAIL";

export const UPDATE_ADCLASS_REQUEST = "UPDATE_ADCLASS_REQUEST";
export const UPDATE_ADCLASS_SUCCESS = "UPDATE_ADCLASS_SUCCESS";
export const UPDATE_ADCLASS_RESET = "UPDATE_ADCLASS_RESET";
export const UPDATE_ADCLASS_FAIL = "UPDATE_ADCLASS_FAIL";

export const RESET_ADCLASS_REQUEST = "RESET_ADCLASS_REQUEST";
export const DELETE_ADCLASS_REQUEST = "DELETE_ADCLASS_REQUEST";
export const DELETE_ADCLASS_SUCCESS = "DELETE_ADCLASS_SUCCESS";
export const DELETE_ADCLASS_FAIL = "DELETE_ADCLASS_FAIL";

export const ALL_ADTYPE_REQUEST = "ALL_ADTYPE_REQUEST";
export const ALL_ADTYPE_SUCCESS = "ALL_ADTYPE_SUCCESS";
export const ALL_ADTYPE_FAIL = "ALL_ADTYPE_FAIL";
export const ALL_ADTYPE_RESET = "ALL_ADTYPE_RESET";

export const NEW_ADTYPE_REQUEST = "NEW_ADTYPE_REQUEST";
export const NEW_ADTYPE_SUCCESS = "NEW_ADTYPE_SUCCESS";
export const NEW_ADTYPE_RESET = "NEW_ADTYPE_RESET";
export const NEW_ADTYPE_FAIL = "NEW_ADTYPE_FAIL";

export const GET_ADTYPE_REQUEST = "GET_ADTYPE_REQUEST";
export const GET_ADTYPE_SUCCESS = "GET_ADTYPE_SUCCESS";
export const GET_ADTYPE_RESET = "GET_ADTYPE_RESET";
export const GET_ADTYPE_FAIL = "GET_ADTYPE_FAIL";

export const UPDATE_ADTYPE_REQUEST = "UPDATE_ADTYPE_REQUEST";
export const UPDATE_ADTYPE_SUCCESS = "UPDATE_ADTYPE_SUCCESS";
export const UPDATE_ADTYPE_RESET = "UPDATE_ADTYPE_RESET";
export const UPDATE_ADTYPE_FAIL = "UPDATE_ADTYPE_FAIL";

export const RESET_ADTYPE_REQUEST = "RESET_ADTYPE_REQUEST";
export const DELETE_ADTYPE_REQUEST = "DELETE_ADTYPE_REQUEST";
export const DELETE_ADTYPE_SUCCESS = "DELETE_ADTYPE_SUCCESS";
export const DELETE_ADTYPE_FAIL = "DELETE_ADTYPE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
