import Cookies from "universal-cookie";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API from "../config.json";
import { getDefaultLang } from "../components/layout/common/getDefaultLang";


// axios.defaults.withCredentials = true;
const getToken = () => {
  const cookies = new Cookies();
  return cookies.get("token");
};

const header = (headerType,lang = "en") => {
  return {
    "Content-Type": headerType,
    "Accept-Language": lang,
    Authorization: getToken(),
  };
};

export const getHttpWithParams = async (
  {url,
  lang=getDefaultLang,
  formData = null,
  headerType = "application/json",
  params = []}
) => {
  const config = {
    headers: header(headerType,lang),
  };

  // path.searchParams.set("page", "1");
  if (formData) {
    console.log("With Data:", formData);
    let { data } = await axios.get(
      `${API.API_URL}/api/v1/${url}`,
      { params: formData },
      config
    );
    return data;
  } else {
    let { data } = await axios.get(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};

export const putHttpWithParams = async ({
  url,
  formData = null,
  lang = getDefaultLang,
  headerType = "application/json",
  customConfig = {},
  params = []
}) => {

  console.log("Put Http");
  
  let config = {
    headers: header(headerType, lang),
    ...customConfig,
  };

  if (formData) {
    let { data } = await axios.put(
      `${API.API_URL}/api/v1/${url}`,
      formData,
      config
    );
    return data;
  } else {
    let { data } = await axios.put(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};

export const getHttp = async (
  url, 
  formData = null,
  headerType = "application/json",
  params = []
) => {
  const config = {
    headers: header(headerType),
  };

  // path.searchParams.set("page", "1");
  if (formData) {
    console.log("With Data:",formData);
    let { data } = await axios.get(
      `${API.API_URL}/api/v1/${url}`,
      {params:formData},
      config
    );
    return data;
  } else {
    let { data } = await axios.get(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};


export const postHttp = async (
  url,
  formData = null,
  headerType = "application/json"
) => {
  const config = {
    headers: header(headerType),
  };

  if (formData) {
    let { data } = await axios.post(
      `${API.API_URL}/api/v1/${url}`,
      formData,
      config
    );
    return data;
  } else {
    let { data } = await axios.post(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};

export const putHttp = async (
  url,
  formData = null,
  headerType = "application/json",
  customConfig = {}
) => {
  console.log("Put Http");
  let config = {
    headers: header(headerType),
    ...customConfig,
  };

  if (formData) {
    let { data } = await axios.put(
      `${API.API_URL}/api/v1/${url}`,
      formData,
      config
    );
    return data;
  } else {
    let { data } = await axios.put(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};

export const deleteHttp = async (
  url,
  formData = null,
  headerType = "application/json"
) => {
  const config = {
    headers: header(headerType),
  };

  if (formData) {
    let { data } = await axios.get(
      `${API.API_URL}/api/v1/${url}`,
      formData,
      config
    );
    return data;
  } else {
    let { data } = await axios.delete(`${API.API_URL}/api/v1/${url}`, config);
    return data;
  }

  return null;
};
